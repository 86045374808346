<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Bins')}}</h2>
      <vs-button v-if="$store.state.AppActiveUser.userRole === 'fc-operations-supervisor'" @click="openBinModal()" color="primary" icon-pack="feather" icon="icon-plus" size="large"></vs-button>
    </div>
    <vs-button :disabled="selected.length === 0" @click="confirmStore()" color="success" class="py-2 px-3 mb-2">{{ $t('Store Bins') }}</vs-button>
    <head-component :noActions="true" :statusFilters="statusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event" @loadShipments="loadBins"></head-component>
    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      :data="bins"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('No. Products')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'fc-operations-supervisor' || $store.state.AppActiveUser.userRole === 'fc-storing-agent'">{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{name: `${$store.state.AppActiveUser.userRole}-bin-logs-view`, params: {binID: data[indextr].id}}">
          <shipblu-td :data="data[indextr].name">
            {{ data[indextr].name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].numberOfPackages">
            {{ data[indextr].number_of_products }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" class="whitespace-nowrap">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td v-if="$store.state.AppActiveUser.userRole === 'fc-operations-supervisor' || $store.state.AppActiveUser.userRole === 'fc-storing-agent'">
            <div @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                class="dd-actions cursor-pointer"
                @click="selected = []"
              >
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-more-horizontal"
                >
                </vs-button>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="editBin(data[indextr])"
                  >
                    <span class="flex gap-2 items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                      />
                      <span>{{$t('Edit Bin')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="deleteBin(data[indextr])"
                  >
                    <span class="flex gap-2 items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                      />
                      <span>{{$t('Delete Bin')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item :disabled="data[indextr].status === 'stored' || data[indextr].status === 'created'"
                    @click="productsInBin(data[indextr])"
                  >
                    <span class="flex gap-2 items-center">
                      <feather-icon
                        icon="BoxIcon"
                        svgClasses="h-4 w-4"
                      />
                      <span>{{$t('Store Products')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-bins v-if="$store.state.AppActiveUser.userRole === 'fc-operations-supervisor' || $store.state.AppActiveUser.userRole === 'fc-storing-agent' " :binModal="binModal" :binEditing="binEditing"  :binsEditData="binsEditData" @binModal="binModal = $event" @loadBins="loadBins"></add-bins>
  </div>
</template>

<script>
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import addBins from './components/AddBins.vue'
import common from '@/assets/utils/common'
import {sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import i18nData from '../../i18n/i18nData.js'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import HeadComponent from '../merchant/components/Header.vue'


export default {
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    addBins,
    ShipbluPagination,
    HeadComponent
  },
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      binModal : false,
      binEditing: false,
      totalRows: 0,
      selected: [],
      currentPage: (this.$route.query.page && Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      offset: 0,
      bins: [],
      deleteData:{},
      binsEditData: {},
      tableLoader: false,
      statusFilters: [
        {
          name: 'All',
          value: 'all',
          color: ''
        },
        {
          name: 'Created',
          value: 'created'
        },
        {
          name: 'inbounded',
          value: 'inbounded'
        },
        {
          name: 'stored',
          value: 'stored'
        }
      ],
      filters: [],
      filterValue: ''
    }
  },
  methods: {
    productsInBin (data) {
      this.$router.push({
        name: 'fc-operations-supervisor-store-product-in-bins',
        params: {
          binID: data.id
        }
      })
    },
    confirmStore () {
      this.$vs.dialog({
        color: 'success',
        title: 'Confirm',
        text: 'Are you sure you want to confirm storing these bins?',
        accept: this.storeBin
      })
    },
    storeBin () {
      const body = []
      this.selected.forEach(item => {
        body.push({
          bin: item.id,
          status: 'stored'
        })
      })
      sendFulfillmentRequest(false, false, this, `api/v1/fc/bins/${this.selected[0].id}/tracking/`, 'post', body, true,
        () => {
          this.loadBins()
        }
      )
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadBins()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    deleteBin (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/bins/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['bin'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadBins()
        }
      )
    },
    editBin (data) {
      this.binModal = true
      this.binsEditData = data
      this.binEditing = true
    },
    openBinModal () {
      this.binModal = true
      this.binsEditData = {}
      this.binEditing = false
    },
    loadBins () {
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&statuses=${this.filters}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/bins/${query}`, 'get', null, true,
        (response) => {
          this.bins = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadBins()
    }
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadBins()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>